import React from "react";
import { Link } from "react-router-dom";
import { MdArrowOutward, MdNavigateNext } from "react-icons/md";
import parse from "html-react-parser";
import Image from "../../components/Image";
import Dropdown from "react-bootstrap/Dropdown";
import { FiArrowUpRight } from "react-icons/fi";
function DiningCard({
  diningImage,
  catDining,
  diningName,
  diningTime,
  diningDes,
  diningMenuLink,
  diningLinkTarget,
  showMenu,

  learnMore,
  diningcards,
  endDiningTime,
  endTime = false,
  restTagline = "",
  tags,
  slug,
  diningImgAltImg,
  restaurantMenus = [],
}) {
  const menuNotFound = "justify-content-end";
  const menuFound = "justify-content-between";

  function truncateAtLastWord(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }

    const truncatedText = text.slice(0, maxLength);
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    return truncatedText;
  }

  return (
    <>
      <div className="col-lg-6">
        <div className={`dining-card`}>
          <div className="dining-card-header overflow-hidden position-relative">
            <Image src={diningImage} alt={diningImgAltImg} title="" />
            {catDining ? (
              <span
                className="dining-cat position-absolute start-0 top-0 m-3 text-white text-uppercase"
                style={{ backgroundColor: `${diningcards}` }}
              >
                <small>{catDining}</small>
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="dining-card-body border-top-0">
            <h5 className="georgia text-uppercase">{diningName}</h5>
            <div className="dining-timing">
              {tags &&
                tags.some((tag) => tag !== null) &&
                tags.map((item, index) => (
                  <span key={index} className="me-2">
                    {item}
                  </span>
                ))}
            </div>

            {/* <p>{diningDes}</p> */}
            <p>
              {restTagline?.length > 100 ? (
                <>
                  {parse(truncateAtLastWord(restTagline, 100))}

                  {" ...."}
                </>
              ) : (
                parse(restTagline === null ? "" : restTagline)
              )}
              {/* place tagline here */}
            </p>
            <div
              className={`d-flex align-items-center gap-2 sm:gap-3  ${menuFound} ${menuNotFound}`}
            >
              {restaurantMenus?.length !== 0 ? (
                <div className="d-flex align-items-center">
                  {restaurantMenus?.length > 1 ? (
                    <div className="dinning-drpdwn">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="georgia btn  w-auto ms-0 showMenu  theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme remove-drop"
                        >
                          Show Menu <MdNavigateNext />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="bg-white">
                          {restaurantMenus?.map((data, index) => (
                            <Dropdown.Item
                              href={data.menu_pdf}
                              key={index}
                              target="_blank"
                            >
                              {data.menu_name}{" "}
                              <FiArrowUpRight className="smooth arrowbtn ms-2 text-[20px]" />
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <Link
                      className="text-uppercase fw-bold show-menu "
                      to={restaurantMenus[0]?.menu_pdf}
                      target={diningLinkTarget}
                      title={restaurantMenus[0].menu_name}
                      // style={{maxWidth:"250px",overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap'}}
                    >
                      {restaurantMenus[0].menu_name.length > 15
                        ? restaurantMenus[0].menu_name.slice(0, 15)+"..."
                        : restaurantMenus[0].menu_name}{" "}
                      <MdArrowOutward/>
                    </Link>
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <div
                // style={{
                //   minWidth: "172px",
                //   textAlign: "right",
                //   "@media(max-width:420px)": {
                //     minWidth: "fit-content",
                //   },
                // }}
                className="min-w-[172px] text-right min-[420px]:min-w-[142px]"
              >
                <Link
                  to={slug}
                  className="georgia btn w-auto ms-0 theme-btn-outline btn-outline-success fw-medium text-text-capitalize border-theme"
                >
                  {learnMore} <MdNavigateNext />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiningCard;
